<template>
  <v-card flat class="mx-9 mt-9 card3 rounded-lg" color="#F1F3F4">
    <div class="card-wrapper">
      <div class="d-flex" v-for="(item, index) in details.items" :key="index">
        <div class="left text-capitalize grey--text">
          {{ item.text }}
        </div>

        <div
          class="right text-left px-7"
          :class="{ 'font-weight-black': item.bold }"
        >
          {{ item.value }}
        </div>
      </div>
    </div>

    <v-divider class="my-4"></v-divider>

    <div class="ml-2">
      <v-btn icon>
        <v-icon color="black">mdi-link</v-icon>
      </v-btn>

      <span
        class="text-decoration-underline text-capitalize font-color-dark footer"
        >{{ details.footer }}</span
      >
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["details"]
};
</script>

<style lang="scss" scoped>
.card3 {
  margin-bottom: 0 !important;

  .footer {
    cursor: pointer;
  }

  .grey--text,
  .text-left {
    font-size: 14px !important;
    letter-spacing: 1.2px;
  }
}

.card-wrapper {
  min-height: 130px;
  padding-top: 20px;
}

.left {
  padding-left: 20px;
}

.right {
  width: 85%;
}
</style>
